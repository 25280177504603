<template>
  <div>
    <b-overlay
      id="overlay-background"
      v-if="!hasLoaded"
      fixed
      no-wrap
      variant="light"
      opacity="0.85"
      blur="5px"
    >
    </b-overlay>
    <div v-else>
      <div class="row custom_error" v-if="error">
        <p style="color:red;">{{ error }} </p>
      </div>
      <wizard v-if="!error" :company="company"></wizard>
    </div>
  </div>
</template>

<script>
import Wizard from './Wizard'
import CompanyService from 'src/services/CompanyService'
export default {
  data() {
    return {
      error: null,
      hasLoaded: false,
      company: null
    }
  },
  components: {
    Wizard
  },
  async mounted() {
    try {
      const company = await CompanyService.getCompany(this.$route.params.id)
      this.company = company.data
      console.log('company', this.company)
    } catch(err) {
      console.log('err', err)
      this.error = 'Errore durante la get della company!'
    } finally {
      this.hasLoaded = true
    }
  }
}
</script>

<style>

</style>